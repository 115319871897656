import { createSlice } from "@reduxjs/toolkit"

const initialState = {
        title: null,
        category: null,
        tickets: null,
        ticketValue: null,
        campaignDraw: null,
        contactNumber: null,
        campaignPlanId: null,
        unpaidTicketExpiryTime: null,
        unpaidTicketExpiryTimeUnit: null,
        awards: null,
}

const planSlice = createSlice({
    name: 'createRaffle',
    initialState: initialState,
    reducers: {
        setRaffle: (state, action) => {
            return { ...state, ...action.payload };
          },
        resetRaffleState: () => initialState,
    },
})

export const { setRaffle, resetRaffleState } = planSlice.actions

export default planSlice.reducer

export const selectRaffle = (state: any) => state.raffle