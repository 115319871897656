import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { resetRedirectToLogin, selectShouldRedirectToLogin } from 'src/features/auth/authSlice';

function RedirectToLogin() {
  const shouldRedirect = useSelector(selectShouldRedirectToLogin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/auth/sign-in');
      dispatch(resetRedirectToLogin());
    }
  }, [shouldRedirect]);

  return null;
}

export default RedirectToLogin