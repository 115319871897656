import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

const MainPage = Loader(lazy(() => import('src/content/mainPage')));

// Dashboards

const Performance = Loader(lazy(() => import('src/content/dashboards/Performance')));

// Applications

const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const Raffles = Loader(
  lazy(() => import('src/content/applications/Raffles'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

const CreateRaffle = Loader(
  lazy(() => import('src/content/applications/CreateRaffle'))
)

const RaffleDetails = Loader(
  lazy(() => import('src/content/applications/RaffleDetails'))
)

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

// Auth

const SignIn = Loader(
  lazy(() => import('src/content/pages/Auth/Sign-in'))
);

const SignUp = Loader(
  lazy(() => import('src/content/pages/Auth/Sign-up'))
);

const ForgotPassword = Loader(
  lazy(() => import('src/content/pages/Auth/Forgot-password'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <MainPage />
      },
      {
        path: 'mainPage',
        element: <Navigate to="/" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'auth',
    children: [
      {
        path: 'sign-in',
        element: <SignIn />
      },
      {
        path: 'sign-up',
        element: <SignUp />
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      }
    ]
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: (
          <Navigate to="performance" replace />
        )
      },
      {
        path: 'performance',
        element: (
          <Performance />
        )
      }
    ]
  },
  {
    path: 'raffles',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: (
          <Raffles />
        )
      },
      {
        path: 'create',
        element: (
          <CreateRaffle />
        )
      },
      {
        path: ':campaignSlug',
        element: (
          <RaffleDetails />
        )
      },
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: (
          <Transactions />
        )
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: (

              <UserProfile />
            )
          },
          {
            path: 'settings',
            element: (

              <UserSettings />
            )
          }
        ]
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: (
          <Transactions />
        )
      },
      {
        path: 'help',
        children: [
          {
            path: '',
            element: <Navigate to="terms" replace />
          },
          {
            path: 'terms',
            element: (

              <UserProfile />
            )
          },
          {
            path: 'privacy-policy',
            element: (
              <UserSettings />
            )
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
