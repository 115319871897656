import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { apiSlice } from './api/api.Slice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from '../features/auth/authSlice';
import raffleReducer from "../features/raffle/raffleSlice";

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: ['raffle'],
};

const rootReducer = {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    raffle: raffleReducer,
};

const reducer = combineReducers(rootReducer);

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    //@ts-ignore
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PURGE', 'persist/REGISTER'],
            },
        }).concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
