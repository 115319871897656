import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { store, persistor } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import RedirectToLogin from './contexts/RedirectToLogin';

ReactDOM.createRoot(document.getElementById('root')).render(
  <PersistGate loading={<div>Carregando...</div>} persistor={persistor}>
    <Provider store={store}>
      <HelmetProvider>
        <SidebarProvider>
          <BrowserRouter>
            <RedirectToLogin />
            <App />
          </BrowserRouter>
        </SidebarProvider>
      </HelmetProvider>
    </Provider>
  </PersistGate>
);


serviceWorker.unregister();