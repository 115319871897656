import { createSlice } from "@reduxjs/toolkit"
import Cookies from "js-cookie"
const authSlice = createSlice({
    name: 'auth',
    initialState: {  user: { email: null, name: null, role: null  }, shouldRedirectToLogin: false },
    reducers: {
        setCredentials: (state, action) => {
            const { accessToken, refreshToken } = action.payload
            Cookies.set('AT', accessToken, { secure: true, sameSite: 'strict' });
            Cookies.set('RT', refreshToken, { secure: true, sameSite: 'strict' });
        },
        setUser: (state, action) => {            
            const { user } = action.payload
            state.user = user
        },
        logOut: (state) => {
            state.user = null
            state.shouldRedirectToLogin = true;
            Cookies.remove('AT')
            Cookies.remove('RT')
        },
        resetRedirectToLogin: (state) => {
            state.shouldRedirectToLogin = false;
        },
        setAccessToken: (_state, action) => {
            const accessToken = action.payload
            Cookies.set('AT', accessToken, { secure: true, sameSite: 'strict' });
        },
    },
})

export const { setCredentials, logOut, setUser, resetRedirectToLogin, setAccessToken } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: any) => state.auth.user
export const selectCurrentToken = () => Cookies.get('AT')
export const selectShouldRedirectToLogin = (state: any) => state.auth.shouldRedirectToLogin 