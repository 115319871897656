/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { logOut, setAccessToken } from '../../features/auth/authSlice'
import Cookies from 'js-cookie'

interface RefreshResponse {
    accessToken: string;
  }
  

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers) => {
        const accessToken = Cookies.get('AT')

        if (accessToken) {
            headers.set("authorization", `Bearer ${accessToken}`)
        }
        return headers
    }
})

const baseQueryReauth = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers) => {
        const refreshToken = Cookies.get('RT')

        if (refreshToken) {
            headers.set("authorization", `Bearer ${refreshToken}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result?.error?.status === 403 || result?.error?.status === 401 ) {
        const refreshResult = await baseQueryReauth('/auth/refresh', api, extraOptions);
        
        if (refreshResult.data) {
            const { accessToken } = refreshResult.data as RefreshResponse;
            api.dispatch(setAccessToken(accessToken))
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(logOut())
        }
    }

    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: _builder => ({})
})